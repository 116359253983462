const routes = [
  {
    index: true,
    label: 'aggie.pictures',
    path: '/',
  },
  {
    label: 'Stories',
    path: '/stories',
  },
  {
    label: 'Book',
    path: '/book',
  },
];

export default routes;
